var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"card",class:_vm.$style.container},[_c('div',{staticClass:"text-dark font-size-24 mb-4"},[_c('strong',[_vm._v("Create your account")]),_c('p',{staticClass:"font-size-12"},[_vm._v(" Please fill in your personal information below ")]),(_vm.error)?_c('p',{staticClass:"font-size-12",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email address',
                  },
                  {
                    required: true,
                    message: 'Please enter your email address',
                  },
                ],
              },
            ]),expression:"[\n              'email',\n              {\n                rules: [\n                  {\n                    type: 'email',\n                    message: 'Please enter a valid email address',\n                  },\n                  {\n                    required: true,\n                    message: 'Please enter your email address',\n                  },\n                ],\n              },\n            ]"}],attrs:{"has-feedback":"","size":"large","placeholder":"Email Address"}})],1),_c('facility-phone-number-form-item',{attrs:{"showLabel":false}}),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  { required: true, message: 'Please enter your password' },
                  {
                    pattern: /(?=^.{5,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
                    message:
                      'Password must contain atleast a digit, an uppercase and lower case letter, 5 characters minimum and a special character',
                  },
                ],
              },
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  { required: true, message: 'Please enter your password' },\n                  {\n                    pattern: /(?=^.{5,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,\n                    message:\n                      'Password must contain atleast a digit, an uppercase and lower case letter, 5 characters minimum and a special character',\n                  },\n                ],\n              },\n            ]"}],attrs:{"has-feedback":"","size":"large","type":"password","placeholder":"Password"}})],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirm_password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please repeat your password',
                  },
                  {
                    validator: _vm.compareToFirstPassword,
                  },
                ],
              },
            ]),expression:"[\n              'confirm_password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please repeat your password',\n                  },\n                  {\n                    validator: compareToFirstPassword,\n                  },\n                ],\n              },\n            ]"}],attrs:{"size":"large","type":"password","placeholder":"Confirm Password","has-feedback":""},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-button',{staticClass:"text-center w-100",attrs:{"type":"primary","size":"large","loading":_vm.loading,"htmlType":"submit"}},[_c('strong',[_vm._v("Sign Up")])])],1),_c('div',[_c('span',{staticClass:"mr-1"},[_vm._v("By signing up, you agree to the")]),_c('a',{staticClass:"kit__utils__link",attrs:{"href":"/terms","target":"_blank"}},[_vm._v("Terms And Conditions")]),_vm._v(" and "),_c('a',{staticClass:"kit__utils__link",attrs:{"href":"/privacy","target":"_blank"}},[_vm._v("Privacy Policy")])])],1),_c('div',{staticClass:"text-center pt-2 mb-auto"},[_c('span',{staticClass:"mr-2"},[_vm._v("Already have an account?")]),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_vm._v("Sign in")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }